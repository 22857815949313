const sol_347 = `
\`\`\`python
class Solution:
    def topKFrequent(self, nums: List[int], k: int) -> List[int]:
        freq = {}

        for val in nums:
            if val in freq:
                freq[val] += 1
            else:
                freq[val] = 1
        
        buckets = [[] for _ in range(len(nums) + 1)]

        for key in freq:
            buckets[freq[key]].append(key)
        
        output = []
        for bucket in reversed(buckets):
            if len(bucket):
                output = output + bucket
                if (len(output) >= k):
                    return output[:k]

# Store freq
# bucket sort
# reverse over results and return k results
\`\`\`
`;

export default sol_347;
