const sol_25 = `
\`\`\`python
class Solution:
    def longestSubarray(self, nums: List[int]) -> int:
        longest, l, curr = 0, 0, { 0: 0, 1: 0 }

        for val in nums:
            curr[val] += 1

            while(curr[0] >= 2):
                curr[nums[l]] -= 1
                if curr[1] < 0:
                    curr[1] = 0
                
                l += 1
            
            longest = max(longest, curr[1])

        return longest - 1 if longest == len(nums) else longest
\`\`\`
`;

export default sol_25;
