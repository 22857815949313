const sol_811 = `
\`\`\`python
class Solution:
    def subdomainVisits(self, cpdomains: List[str]) -> List[str]:
        total = {}
        output = []

        for domain in cpdomains:
            domain = domain.split(' ')

            amount = int(domain[0])
            subdomains = domain[1].split('.')

            sliceSize = 0
            subdomain = domain[1]

            for size in subdomains:
                if subdomain not in total:
                    total[subdomain] = amount
                else:
                    total[subdomain] += amount

                sliceSize = len(size) + 1
                subdomain = subdomain[sliceSize:]

        for key in total:
            output.append(str(total[key]) + " " + key)
            
        return output
\`\`\`
`;

export default sol_811;
