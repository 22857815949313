const sol_16 = `
\`\`\`python
class Solution:
    def threeSumClosest(self, nums: List[int], target: int) -> int:
        if (len(nums) == 3):
            return sum(nums)

        nums.sort()
        closest = nums[0] + nums[1] + nums[2]

        for i in range(len(nums)):
            l, r = i + 1, len(nums) - 1
            while l < r:
                total = nums[i] + nums[l] + nums[r]

                if (abs(target-total) <= abs(target-closest)):
                    closest = total

                if total < target:
                    l += 1
                elif total > target:
                    r -= 1
                else:
                    return closest

        return closest
\`\`\`
`;

export default sol_16;


