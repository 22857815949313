const sol_25 = `
\`\`\`python
class ListNode:
    def __init__(self, val=0, next=None):
        self.val = val
        self.next = next

class Solution:
    def reverseKGroup(self, head: Optional[ListNode], k: int) -> Optional[ListNode]:
        total, dummy = 0, head

        while(dummy):
            total += 1
            dummy = dummy.next
        
        iter = total // k

        # #### # 

        output, pos = [], 0

        for i in range(iter):
            temp = k
            while(temp):
                output.insert(pos, head.val)
                head = head.next
                temp -= 1

            pos += k
        
        while(head):
            output.append(head.val)
            head = head.next

        start = dummy = ListNode(-1)
        for val in output:
            dummy.next = ListNode(val)
            dummy = dummy.next

        return start.next
\`\`\`
`;

export default sol_25;
