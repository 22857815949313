import Menu from '../../components/Menu/Menu';
import Nav from '../../components/Nav/Nav';
import './Home.css';
import Question from '../../components/Question/Question';
import Questions from '../../classes/questions';
import { useState } from 'react';

const ql = new Questions().questionList;

export default function Home() {
  const [activeQuestion, setActiveQuestion] = useState(ql[0]);

  return (
    <div className="app">
      <Nav />
      <div className="content">
        <Menu setActiveQuestion={setActiveQuestion} questionList={ql} />
        <Question
          id={activeQuestion.getID()}
          title={activeQuestion.getTitle()}
          observations={activeQuestion.getObservations()}
          brute_force={activeQuestion.getBruteForce()}
          solution={activeQuestion.getSolution()}
          spaceComplexity={activeQuestion.getSpaceComplexity()}
          timeComplexity={activeQuestion.getTimeComplexity()}
          link={activeQuestion.getLink()}
        />
      </div>
    </div>
  );
}
