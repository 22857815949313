import './Nav.css';
import { Link } from 'react-router-dom';

export default function Nav() {
  return (
    <div className="nav">
      <div className="navContent">
        <Link to="/" className="title">
          <b>LeetCode Notes</b>
        </Link>
        <a className="link" href={'https://td31.dev'} target="_blank" rel="noopener noreferrer">
          Personal
        </a>
      </div>
    </div>
  );
}
