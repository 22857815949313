export default class Question {
  private id: number;
  private title: string;
  private observations: string | null;
  private brute_force: string | null;
  private solution: string;
  private space_complexity: string;
  private time_complexity: string;
  private link: string;

  constructor(_id: number, _title: string, _ob: string | null, _b_f: string | null, _sol: string, _s_com: string, _t_com: string, _link: string) {
    this.id = _id;
    this.title = _title;
    this.observations = _ob;
    this.brute_force = _b_f;
    this.solution = _sol;
    this.space_complexity = _s_com;
    this.time_complexity = _t_com;
    this.link = _link;
  }

  getID(): number {
    return this.id;
  }

  getTitle(): string {
    return this.title;
  }

  getBruteForce(): string | null {
    return this.brute_force;
  }

  getObservations(): string | null {
    return this.observations;
  }

  getSolution(): string {
    return this.solution;
  }

  getSpaceComplexity(): string {
    return this.space_complexity;
  }

  getTimeComplexity(): string {
    return this.time_complexity;
  }

  getLink(): string {
    return this.link;
  }
}
