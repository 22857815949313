import './Menu.css';
import Question from '../../classes/question';

interface iMenu {
  setActiveQuestion: Function;
  questionList: Question[];
}

export default function Menu({ setActiveQuestion, questionList }: iMenu) {
  return (
    <div className="menu">
      {/* <div className="search">
        <input></input>
      </div> */}
      {/* <div className="divider"></div> */}
      <div className="questions">
        {questionList.map((question) => (
          <div className="questionName" onClick={() => setActiveQuestion(question)}>
            {question.getID()}. {question.getTitle()}
          </div>
        ))}
      </div>
    </div>
  );
}
