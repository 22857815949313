const sol_107 = `
\`\`\`python
class TreeNode:
    def __init__(self, val=0, left=None, right=None):
        self.val = val
        self.left = left
        self.right = right

    def __repr__(self):
        return str(self.val)

class Solution:
    def levelOrderBottom(self, root):
        if not root:
            return root

        if not root.left and not root.right:
            return [[root.val]]

        output = []
        current = [root]

        while current:
            next, level = [], []

            for node in current:
                level.append(node.val)

                if node.left:
                    next.append(node.left)

                if node.right:
                    next.append(node.right)

            output.insert(0, level)
            current = next

        return output

root = TreeNode(3, TreeNode(9), TreeNode(20, TreeNode(15), TreeNode(7)))

p1 = Solution()
print(p1.levelOrderBottom(root))
\`\`\`
`;

export default sol_107;
