const sol_948 = `
\`\`\`python
class Solution:
    def bagOfTokensScore(self, tokens: List[int], power: int) -> int:
        score, highest, l, r = 0, 0, 0, len(tokens) - 1

        tokens.sort()
        
        if not tokens or tokens[0] > power:
            return 0
        
        while l <= r:
            if power >= tokens[l]:
                score += 1
                highest = max(highest, score)
                power -= tokens[l]
                l += 1
                continue
            
            if score > 0:
                score -= 1
                power += tokens[r]
                r -= 1

        return highest
\`\`\`
`;

export default sol_948;
