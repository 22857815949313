const sol_2105 = `
\`\`\`python
class Solution:
    def minimumRefill(self, plants: List[int], capacityA: int, capacityB: int) -> int:
        currA, currB, refill = capacityA, capacityB, 0
        l, r = 0, len(plants) - 1

        while l <= r:
            if l == r:
                larger = max(currA, currB)
                if plants[l] > larger:
                    refill += 1
                
                break

            if plants[l] > currA:
                refill += 1
                currA = capacityA
            
            currA -= plants[l]

            if plants[r] > currB:
                refill += 1
                currB = capacityB
            
            currB -= plants[r]

            l += 1
            r -= 1

        return refill
\`\`\`
`;

export default sol_2105;
