import Question from './question';
import sol_25 from './solutions/sol_25';
import sol_107 from './solutions/sol_107';
import sol_347 from './solutions/sol_347';
import sol_841 from './solutions/sol_841';
import sol_904 from './solutions/sol_904';
import sol_1817 from './solutions/sol_1817';
import sol_811 from './solutions/sol_811';
import sol_1493 from './solutions/sol_1493';
import sol_948 from './solutions/sol_948';
import sol_2105 from './solutions/sol_2105';
import sol_242 from './solutions/sol_242';
import sol_16 from './solutions/sol_16';

export default class Questions {
  questionList: Question[] = [
new Question(
      16,
      '3Sum Closest',
      null,
      null,
      sol_16,
      'O....',
      'O ...',
      'https://leetcode.com/problems/3sum-closest'
    ),
    new Question(
      25,
      'Reverse Nodes in k-Group',
      null,
      null,
      sol_25,
      'O....',
      'O ...',
      'https://leetcode.com/problems/reverse-nodes-in-k-group'
    ),
    new Question(
      107,
      'Binary Tree Level Order Traversal II',
      null,
      null,
      sol_107,
      'O....',
      'O ...',
      'https://leetcode.com/problems/binary-tree-level-order-traversal-ii'
    ),
new Question(
      242,
      'Valid Anagram',
      null,
      null,
      sol_242,
      'O....',
      'O ...',
      'https://leetcode.com/problems/valid-anagram'
    ),
    new Question(
      347,
      'Top K Frequent Elements',
      null,
      null,
      sol_347,
      'O....',
      'O ...',
      'https://leetcode.com/problems/top-k-frequent-elements'
    ),
    new Question(
      811,
      'Subdomain Visit Count',
      null,
      null,
      sol_811,
      'O....',
      'O ...',
      'https://leetcode.com/problems/subdomain-visit-count'
    ),
    new Question(841, 'Keys and Rooms', null, null, sol_841, 'O....', 'O ...', 'https://leetcode.com/problems/keys-and-rooms'),
    new Question(904, 'Fruit into Baskets', null, null, sol_904, 'O....', 'O ...', 'https://leetcode.com/problems/fruit-into-baskets'),
    new Question(948, 'Bag of Tokens', null, null, sol_948, 'O....', 'O ...', 'https://leetcode.com/problems/bag-of-tokens'),
    new Question(
      1493,
      "Longest Subarray of 1's After Deleting One Element",
      null,
      null,
      sol_1493,
      'O....',
      'O ...',
      'https://leetcode.com/problems/longest-subarray-of-1s-after-deleting-one-element'
    ),
    new Question(
      1817,
      'Finding the Users Active Minutes',
      null,
      null,
      sol_1817,
      'O....',
      'O ...',
      'https://leetcode.com/problems/finding-the-users-active-minutes'
    ),
    new Question(
      2105,
      'Watering Plants II',
      null,
      null,
      sol_2105,
      'O....',
      'O ...',
      'https://leetcode.com/problems/watering-plants-ii'
    ),
  ];
}
