/* eslint-disable jsx-a11y/anchor-has-content */
import './Question.css';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import hljs from 'highlight.js';
// import 'highlight.js/styles/lightfair.css';
import { useEffect } from 'react';
import { ArrowUpRight } from 'react-feather';

interface iQuestion {
  id: number;
  title: string;
  observations: string | null;
  brute_force: string | null;
  solution: string;
  spaceComplexity: string;
  timeComplexity: string;
  link: string;
}

export default function Question({ id, title, observations, brute_force, solution, spaceComplexity, timeComplexity, link }: iQuestion) {
  useEffect(() => {
    hljs.highlightAll();
  }, []);

  return (
    <div className="question">
      <div className="questionHeader">
        <b>
          <u>
            <a className="" href={link} target="_blank" rel="noopener noreferrer">
              {id}. {title}
            </a>
          </u>
        </b>
        <ArrowUpRight size={24} />
      </div>
      {/* {brute_force ? (
        <>
          <div>
            <b>Brute Force:</b>
          </div>
          <div className="loadedData">{brute_force}</div>
        </>
      ) : null} */}
      <div className="loadedData" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(solution)) }}></div>
      <br />
      <div>
        <b>Complexity:</b>
      </div>
      <div className="complexityValues">
        <table>
          <tr>
            <td>
              <i>Space:</i>
            </td>
            <td>{spaceComplexity}</td>
          </tr>
          <tr>
            <td>
              <i>Time:</i>
            </td>
            <td>{timeComplexity}</td>
          </tr>
        </table>
      </div>
      {observations ? (
        <>
          <div>
            <b>Observations:</b>
          </div>
          <div className="loadedData">{observations}</div>
        </>
      ) : null}
    </div>
  );
}
